@font-face {
  font-family: 'DongHo';
  src: local(''), url('../../assets/font/Uni Thuphap (30).ttf') format('truetype'); /* format('truetype');

   /* unicode-range: U+0020-U+002F, U+0030-U+0039, U+003A-U+0040, U+0041-U+005A, U+005B-U+0060, U+0061-U+007A, U+007B-U+007E, U+00C0-U+00C3, U+00C8-U+00CA, U+00CC-U+00CD, U+00D0, U+00D2-U+00D5, U+00D9-U+00DA, U+00DD, U+00E0-U+00E3, U+00E8-U+00EA, U+00EC-U+00ED, U+00F2-U+00F5, U+00F9-U+00FA, U+00FD, U+0102-U+0103, U+0110-U+0111, U+0128-U+0129, U+0168-U+0169, U+01A0-U+01B0, U+1EA0-U+1EF9, U+02C6-U+0323; / */

  /* unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; / */
}

.cssTitle {
  font-family: DongHo;
  font-size: 70px;
}

@media only screen and (max-width: 768px) {
  .cssTitle {
      font-size: 50px;
  }
}

/* @media only screen and (max-width: 640px) {
  .cssTitle {
      font-size: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .cssTitle {
      font-size: 22px;
  }
} */

.cssHomePageTitle {
  font-family: DongHo;
  font-size: 35px;
}
/* @media only screen and (max-width: 768px) {
  .cssTitle {
      font-size: 35px;
  }
}

@media only screen and (max-width: 640px) {
  .cssTitle {
      font-size: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .cssTitle {
      font-size: 22px;
  }
} */

.sweet-alert .icon {
  width: 80px;
  height: 80px;
  border: 4px solid #808080;
  border-radius: 50%;
  margin: 20px auto;
  box-sizing: content-box;
  position: relative;
}

.sweet-alert .icon.success {
  border-color: #1F7A1F;
}

.sweet-alert .icon.success .line {
  position: absolute;
  height: 5px;
  background-color: #1F7A1F;
}

.sweet-alert .icon.success .line.tip {
  width: 25px;
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  z-index: 2;
}

.sweet-alert .icon.success .line.long {
  width: 47px;
  top: 38px;
  right: 8px;
  transform: rotate(-45deg);
  z-index: 2;
}

.sweet-alert .icon.success::before,
.sweet-alert .icon.success::after {
  content: '';
  position: absolute;
  height: 120px;
  width: 60px;
  transform: rotate(45deg);
}

.sweet-alert .icon.success::before {
  left: -33px;
  top: -7px;
  transform: rotate(-45deg);
  transform-origin: 60px 60px;
  border-radius: 120px 0 0 120px;
}

.sweet-alert .icon.success::after {
  top: -11px;
  left: 30px;
  transform: rotate(-180deg);
  transform-origin: 0 60px;
  border-radius: 0 120px 120px 0;
}

.sweet-alert .icon.success .placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(164, 219, 132, 0.2);
  position: absolute;
  left: -4px;
  top: -4px;
  box-sizing: content-box;
  border-radius: 50%;
  z-index: 2;
}

.sweet-alert .icon.success .fix {
  position: absolute;
  left: 28px;
  top: 8px;
  width: 5px;
  height: 90px;
  transform: rotate(-45deg);
  z-index: 1;
}

@keyframes animateSuccessTip {

  0%,
  54% {
      left: 1px;
      top: 19px;
      width: 0;
  }

  70% {
      left: -8px;
      top: 37px;
      width: 50px;
  }

  84% {
      left: 21px;
      top: 48px;
      width: 17px;
  }

  100% {
      left: 14px;
      top: 45px;
      width: 25px;
  }
}

.animateSuccessTip {
  animation: animateSuccessTip 2s infinite;
}

@keyframes animateSuccessLong {

  0%,
  65% {
      right: 46px;
      top: 54px;
      width: 0;
  }

  84% {
      right: 0;
      top: 35px;
      width: 55px;
  }

  100% {
      right: 8px;
      top: 38px;
      width: 47px;
  }
}

.animateSuccessLong {
  animation: animateSuccessLong 2s infinite;
}

@keyframes rotatePlaceholder {

  0%,
  5% {
      transform: rotate(-45deg);
  }

  100%,
  12% {
      transform: rotate(-405deg);
  }
}

.sweet-alert .icon.success.animate::after {
  animation: rotatePlaceholder 12s ease-in infinite;
}

.sweet-alert .icon {
  width: 80px;
  height: 80px;
  border: 4px solid #808080;
  border-radius: 50%;
  margin: 20px auto;
  box-sizing: content-box;
  position: relative;
}

.sweet-alert .icon.error {
  border-color: #C04000;
}

.sweet-alert .icon.error .line {
  position: absolute;
  top: 37px;
  width: 47px;
  height: 5px;
  background: #C04000;
  display: block;
}

.sweet-alert .icon.error .line.left {
  left: 17px;
  transform: rotate(45deg);
}

.sweet-alert .icon.error .line.right {
  right: 16px;
  transform: rotate(-45deg);
}

@keyframes animateXMark {

  0%,
  50% {
      margin-top: 26px;
      opacity: 0;
      transform: scale(.4);
  }

  80% {
      margin-top: -6px;
      transform: scale(1.15);
  }

  100% {
      margin-top: 0;
      opacity: 1;
      transform: scale(1);
  }
}

.animateXMark {
  animation: animateXMark .5s;
}

@keyframes animateErrorIcon {
  0% {
      opacity: 0;
      transform: rotateX(100deg);
  }

  100% {
      opacity: 1;
      transform: rotateX(0deg);
  }
}

.animateErrorIcon {
  animation: animateErrorIcon .5s;
}
