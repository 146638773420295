/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px){
  .coverbox {
    width: 95%;
  }
  .RootBoxs {
    display: flex;
    flex-direction: column;
  }
  .FindBox {
    flex-direction: column;
    justify-content: flex-start;
  }
  .DivTable {
    height: 90px;
    /* display: flex;
    justify-content: center; */
  }
  .ButtonDiv {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px){
  .coverbox {
    width: 95%;
  }
}

@media screen and (min-width: 1280px){
  .coverbox {
    width: 100%;
  }
}

@media screen and (max-width: 1280px){
  .RootBoxs{
    flex-direction: column;
  }
}


