.titlee {
    align-self : center
}

/*Iphone(480 x 640)*/
@media screen and (min-width: 320px){
    .loginsheet {
        width: 280px;
        height: fit-content;
    }
}
/*Tablet nhỏ(480 x 640)*/
@media screen and (min-width: 480px){
    .loginsheet {
        width: 300px;
        height: fit-content;
    }
}
/*Ipad dọc(768 x 1024)*/
@media screen and (min-width: 768px){
    .loginsheet {
        width: 400px;
        height: fit-content;
    }
}
/*Ipad ngang(1024 x 768)*/
@media screen and (min-width: 1024px){
    .loginsheet {
        width: 500px;
        height: fit-content;
    }
}


