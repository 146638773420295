.MainBody {
  min-width : 400px;
  min-height: 100px;
}
.mainCard {
  min-width : 350px;
}
@media screen and (max-width: 320px){
  
  .mainCard {
      width: 280px;
      
  }
}
/*Tablet nhỏ(480 x 640)*/
@media screen and (min-width: 480px){
  .mainCard {
      width: 400px;
      
  }
}
/*Ipad dọc(768 x 1024)*/
@media screen and (min-width: 768px){
  .mainCard {
      width: 400px;
      
  }
}
/*Ipad ngang(1024 x 768)*/
@media screen and (min-width: 1024px){
  .mainCard {
      width: 500px;
      
  }
}