body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 900px){
  .Main {
      display: flex;
  }
}

/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 1280px){
  .coverboxOrder {
    width: 100%;
  }
  .RootBox {
    display: flex;
    flex-direction: column;
  }
  .FindBox {
    flex-direction: column;
    justify-content: flex-start;
  }
  .DivTable {
    height: 90px;
    /* display: flex;
    justify-content: center; */
  }
  .ButtonDiv {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1280px){
  .coverboxOrder {
    width: 100%;
  }
  .Number {
    display: flex;
    
  }
  .RootBox {
    display: flex;
    flex-direction: column;
  }
  .Number div {
    flex: 1 1;
  }
  .Money {
    display: flex;
  }
  .Money div {
    flex: 2 1;
  }
}

@media screen and (max-width: 1760px){
  .root_box {
    width: 93%;
  }
}

@media screen and (min-width: 1650px){
  .coverboxOrder {
    width: calc(75vw + 150px);
  }
}

@media screen and (min-width: 1400px){
  .RootBox {
    display: flex;
    flex-direction: row;
  }
}

.react-datepicker-popper {
  z-index: 1000;
}


.titlee {
    align-self : center
}

/*Iphone(480 x 640)*/
@media screen and (min-width: 320px){
    .loginsheet {
        width: 280px;
        height: -moz-fit-content;
        height: fit-content;
    }
}
/*Tablet nhỏ(480 x 640)*/
@media screen and (min-width: 480px){
    .loginsheet {
        width: 300px;
        height: -moz-fit-content;
        height: fit-content;
    }
}
/*Ipad dọc(768 x 1024)*/
@media screen and (min-width: 768px){
    .loginsheet {
        width: 400px;
        height: -moz-fit-content;
        height: fit-content;
    }
}
/*Ipad ngang(1024 x 768)*/
@media screen and (min-width: 1024px){
    .loginsheet {
        width: 500px;
        height: -moz-fit-content;
        height: fit-content;
    }
}



/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .coverbox {
    width: 95%;
  }
  .RootBox {
    display: flex;
    flex-direction: column;
  }
  .FindBox {
    flex-direction: column;
    justify-content: flex-start;
  }
  .DivTable {
    height: 90px;
    /* display: flex;
    justify-content: center; */
  }
  .ButtonDiv {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1280px) {
  .coverboxs {
    width: 100%;
  }
}

@media screen and (min-width: 1400px) {
  .coverboxs {
    width: 100%;
  }
}

@media screen and (min-width: 1600px) {
  .coverboxs {
    width: 85%;
  }
}

.MuiTabs-root {
  overflow: auto;
}

.MuiTabList-root {
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  z-index: 1000;
}

.react-datepicker-popper {
  z-index: 10000;
}
.ListService th {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: #ffffff;
  text-align: center;
  vertical-align: middle !important;
  padding: 0;
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.ListService td {
  text-align: center;
  vertical-align: middle !important;
  padding: 2px;
  background-color: transparent !important;
  border-bottom-left-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.updatePackage td,
.updatePackage th {
  padding-bottom: 0;
  padding-top: 0;
}

.InputForm {
    min-width: 350px;
}
.MainBody {
  min-width : 400px;
  min-height: 100px;
}
.mainCard {
  min-width : 350px;
}
@media screen and (max-width: 320px){
  
  .mainCard {
      width: 280px;
      
  }
}
/*Tablet nhỏ(480 x 640)*/
@media screen and (min-width: 480px){
  .mainCard {
      width: 400px;
      
  }
}
/*Ipad dọc(768 x 1024)*/
@media screen and (min-width: 768px){
  .mainCard {
      width: 400px;
      
  }
}
/*Ipad ngang(1024 x 768)*/
@media screen and (min-width: 1024px){
  .mainCard {
      width: 500px;
      
  }
}
/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px){
  .coverboxs {
    width: 95%;
  }
  .FindBox {
    flex-direction: column;
    justify-content: flex-start;
  }
  .DivTable {
    height: 90px;
    /* display: flex;
    justify-content: center; */
  }
  .ButtonDiv {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px){
  .coverboxs {
    width: 95%;
  }
}

@media screen and (min-width: 1280px){
  .coverboxs {
    width: 90%;
  }
}

@media screen and (min-width: 1400px){
  .coverboxs {
    width: 95%;
  }
}

.InputForm {
    min-width: 220px;
}
/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px){
  .coverbox {
    width: 95%;
  }
  .RootBoxs {
    display: flex;
    flex-direction: column;
  }
  .FindBox {
    flex-direction: column;
    justify-content: flex-start;
  }
  .DivTable {
    height: 90px;
    /* display: flex;
    justify-content: center; */
  }
  .ButtonDiv {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px){
  .coverbox {
    width: 95%;
  }
}

@media screen and (min-width: 1280px){
  .coverbox {
    width: 100%;
  }
}

@media screen and (max-width: 1280px){
  .RootBoxs{
    flex-direction: column;
  }
}



.InputForm {
    min-width: 220px;
}
@font-face {
  font-family: 'DongHo';
  src: local(''), url("/static/media/Uni Thuphap (30).7ec8c258.ttf") format('truetype'); /* format('truetype');

   /* unicode-range: U+0020-U+002F, U+0030-U+0039, U+003A-U+0040, U+0041-U+005A, U+005B-U+0060, U+0061-U+007A, U+007B-U+007E, U+00C0-U+00C3, U+00C8-U+00CA, U+00CC-U+00CD, U+00D0, U+00D2-U+00D5, U+00D9-U+00DA, U+00DD, U+00E0-U+00E3, U+00E8-U+00EA, U+00EC-U+00ED, U+00F2-U+00F5, U+00F9-U+00FA, U+00FD, U+0102-U+0103, U+0110-U+0111, U+0128-U+0129, U+0168-U+0169, U+01A0-U+01B0, U+1EA0-U+1EF9, U+02C6-U+0323; / */

  /* unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; / */
}

.cssTitle {
  font-family: DongHo;
  font-size: 70px;
}

@media only screen and (max-width: 768px) {
  .cssTitle {
      font-size: 50px;
  }
}

/* @media only screen and (max-width: 640px) {
  .cssTitle {
      font-size: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .cssTitle {
      font-size: 22px;
  }
} */

.cssHomePageTitle {
  font-family: DongHo;
  font-size: 35px;
}
/* @media only screen and (max-width: 768px) {
  .cssTitle {
      font-size: 35px;
  }
}

@media only screen and (max-width: 640px) {
  .cssTitle {
      font-size: 22px;
  }
}

@media only screen and (max-width: 480px) {
  .cssTitle {
      font-size: 22px;
  }
} */

.sweet-alert .icon {
  width: 80px;
  height: 80px;
  border: 4px solid #808080;
  border-radius: 50%;
  margin: 20px auto;
  box-sizing: content-box;
  position: relative;
}

.sweet-alert .icon.success {
  border-color: #1F7A1F;
}

.sweet-alert .icon.success .line {
  position: absolute;
  height: 5px;
  background-color: #1F7A1F;
}

.sweet-alert .icon.success .line.tip {
  width: 25px;
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  z-index: 2;
}

.sweet-alert .icon.success .line.long {
  width: 47px;
  top: 38px;
  right: 8px;
  transform: rotate(-45deg);
  z-index: 2;
}

.sweet-alert .icon.success::before,
.sweet-alert .icon.success::after {
  content: '';
  position: absolute;
  height: 120px;
  width: 60px;
  transform: rotate(45deg);
}

.sweet-alert .icon.success::before {
  left: -33px;
  top: -7px;
  transform: rotate(-45deg);
  transform-origin: 60px 60px;
  border-radius: 120px 0 0 120px;
}

.sweet-alert .icon.success::after {
  top: -11px;
  left: 30px;
  transform: rotate(-180deg);
  transform-origin: 0 60px;
  border-radius: 0 120px 120px 0;
}

.sweet-alert .icon.success .placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(164, 219, 132, 0.2);
  position: absolute;
  left: -4px;
  top: -4px;
  box-sizing: content-box;
  border-radius: 50%;
  z-index: 2;
}

.sweet-alert .icon.success .fix {
  position: absolute;
  left: 28px;
  top: 8px;
  width: 5px;
  height: 90px;
  transform: rotate(-45deg);
  z-index: 1;
}

@keyframes animateSuccessTip {

  0%,
  54% {
      left: 1px;
      top: 19px;
      width: 0;
  }

  70% {
      left: -8px;
      top: 37px;
      width: 50px;
  }

  84% {
      left: 21px;
      top: 48px;
      width: 17px;
  }

  100% {
      left: 14px;
      top: 45px;
      width: 25px;
  }
}

.animateSuccessTip {
  animation: animateSuccessTip 2s infinite;
}

@keyframes animateSuccessLong {

  0%,
  65% {
      right: 46px;
      top: 54px;
      width: 0;
  }

  84% {
      right: 0;
      top: 35px;
      width: 55px;
  }

  100% {
      right: 8px;
      top: 38px;
      width: 47px;
  }
}

.animateSuccessLong {
  animation: animateSuccessLong 2s infinite;
}

@keyframes rotatePlaceholder {

  0%,
  5% {
      transform: rotate(-45deg);
  }

  100%,
  12% {
      transform: rotate(-405deg);
  }
}

.sweet-alert .icon.success.animate::after {
  animation: rotatePlaceholder 12s ease-in infinite;
}

.sweet-alert .icon {
  width: 80px;
  height: 80px;
  border: 4px solid #808080;
  border-radius: 50%;
  margin: 20px auto;
  box-sizing: content-box;
  position: relative;
}

.sweet-alert .icon.error {
  border-color: #C04000;
}

.sweet-alert .icon.error .line {
  position: absolute;
  top: 37px;
  width: 47px;
  height: 5px;
  background: #C04000;
  display: block;
}

.sweet-alert .icon.error .line.left {
  left: 17px;
  transform: rotate(45deg);
}

.sweet-alert .icon.error .line.right {
  right: 16px;
  transform: rotate(-45deg);
}

@keyframes animateXMark {

  0%,
  50% {
      margin-top: 26px;
      opacity: 0;
      transform: scale(.4);
  }

  80% {
      margin-top: -6px;
      transform: scale(1.15);
  }

  100% {
      margin-top: 0;
      opacity: 1;
      transform: scale(1);
  }
}

.animateXMark {
  animation: animateXMark .5s;
}

@keyframes animateErrorIcon {
  0% {
      opacity: 0;
      transform: rotateX(100deg);
  }

  100% {
      opacity: 1;
      transform: rotateX(0deg);
  }
}

.animateErrorIcon {
  animation: animateErrorIcon .5s;
}

