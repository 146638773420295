/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px){
  .coverboxs {
    width: 95%;
  }
  .FindBox {
    flex-direction: column;
    justify-content: flex-start;
  }
  .DivTable {
    height: 90px;
    /* display: flex;
    justify-content: center; */
  }
  .ButtonDiv {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px){
  .coverboxs {
    width: 95%;
  }
}

@media screen and (min-width: 1280px){
  .coverboxs {
    width: 90%;
  }
}

@media screen and (min-width: 1400px){
  .coverboxs {
    width: 95%;
  }
}
