/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 768px) {
  .coverbox {
    width: 95%;
  }
  .RootBox {
    display: flex;
    flex-direction: column;
  }
  .FindBox {
    flex-direction: column;
    justify-content: flex-start;
  }
  .DivTable {
    height: 90px;
    /* display: flex;
    justify-content: center; */
  }
  .ButtonDiv {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1280px) {
  .coverboxs {
    width: 100%;
  }
}

@media screen and (min-width: 1400px) {
  .coverboxs {
    width: 100%;
  }
}

@media screen and (min-width: 1600px) {
  .coverboxs {
    width: 85%;
  }
}

.MuiTabs-root {
  overflow: auto;
}

.MuiTabList-root {
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  z-index: 1000;
}

.react-datepicker-popper {
  z-index: 10000;
}
.ListService th {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: #ffffff;
  text-align: center;
  vertical-align: middle !important;
  padding: 0;
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.ListService td {
  text-align: center;
  vertical-align: middle !important;
  padding: 2px;
  background-color: transparent !important;
  border-bottom-left-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.updatePackage td,
.updatePackage th {
  padding-bottom: 0;
  padding-top: 0;
}
