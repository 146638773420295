/*Ipad dọc(768 x 1024)*/
@media screen and (max-width: 1280px){
  .coverboxOrder {
    width: 100%;
  }
  .RootBox {
    display: flex;
    flex-direction: column;
  }
  .FindBox {
    flex-direction: column;
    justify-content: flex-start;
  }
  .DivTable {
    height: 90px;
    /* display: flex;
    justify-content: center; */
  }
  .ButtonDiv {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1280px){
  .coverboxOrder {
    width: 100%;
  }
  .Number {
    display: flex;
    
  }
  .RootBox {
    display: flex;
    flex-direction: column;
  }
  .Number div {
    flex: 1;
  }
  .Money {
    display: flex;
  }
  .Money div {
    flex: 2;
  }
}

@media screen and (max-width: 1760px){
  .root_box {
    width: 93%;
  }
}

@media screen and (min-width: 1650px){
  .coverboxOrder {
    width: calc(75vw + 150px);
  }
}

@media screen and (min-width: 1400px){
  .RootBox {
    display: flex;
    flex-direction: row;
  }
}

.react-datepicker-popper {
  z-index: 1000;
}

